.paginator {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  user-select: none;
}

.paginator__page:hover, .paginator__prev-control-link:hover, .paginator__next-control-link:hover {
  background-color: #e0e0e0;
}

.paginator__page-link {
  display: inline-block;
  padding: 3px 10px;
  outline: none;
}

.paginator__page--current {
  background-color: #2199e8;
  cursor: default;
}

.paginator__page--current:hover {
  background-color: #2199e8;
}

.paginator__page--current a {
  color: #ffffff;
}

.paginator__prev-control-link, .paginator__next-control-link  {
  display: inline-block;
  padding: 3px 8px;
  outline: none;
}

.paginator__prev-control:before {
  content: '\AB';
  display: inline-block;
  margin-right: 0.5rem;
}

.paginator__next-control:after {
  content: '\BB';
  display: inline-block;
  margin-left: 0.5rem;
}