/* Logotipo *
**************/

.app-header__logo-wrapper {
  max-height: 48px;
  height: 48px;
}

.app-header__logo {
  height: 100%;
}

.app-header__logo-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0,0,0,0.6);
}

.app-header__logo-info-left {
  width: 75%;
  border-right: 1px solid #eceae9;
}

.app-header__logo-info-right {
  width: 25%;
}

.app-header__logo-info-wrapper {
  max-width: 100%;
  text-align: center;
}

.app-header__logo-info-img {
  width: 80%;
}

.app-header__logo-info-address {
  line-height: 1.5;
  font-size: 16px;
}

.app-header__logo-info-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
}

.app-header__logo-info-link {
  color: #00a757;
  font-size: 25px;
}

.app-header__logo-info-arrow {
  position: absolute;
  top: -10px;
  left: 20px;
  color: #FFF;
  font-size: 27px;
}

/* Toogle *
************/

.app-header__navbar-toggle {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  right: 15px;
  top: 20px;
}

.app-header__toggle-icon {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
  background-color: #3E3935;
  margin-top: 4px;
}

/* Navbar *
************/
.app-header__navbar {
  height: 0;
  overflow: hidden;
  transition: height 0.5s ease;
}

.app-header__navbar--open {
  height: auto;
}

.app-header__navbar-items {
  margin: 0;
  padding: 0;
  padding-top: 20px;
  list-style-type: none;
}

.app-header__navbar-item {
  display: block;
  padding: 10px 15px;
  line-height: 20px;
  cursor: pointer;
  text-decoration: none;
}

.app-header__navbar-item:focus {
  outline: none;
}

.app-header__navbar-item--active {
  background-color: #f2f4f6;
  border-bottom: 6px solid #3e3935;
}

.app-header__navbar-logout {
  font-family: "GothamMedium";
}

/************************
* Media queries
*************************/

@media only screen and (min-width: 992px) {
  .app-header__navbar-toggle {
    display: none;
  }

  .app-header__navbar {
    height: auto;
    overflow: initial;
    display: flex;
    flex-grow: 1;
    transition: height 0.5s ease;
  }

  .app-header__navbar-items {
    display: flex;
    padding-top: 0;
    margin-left: 85px;
  }
  
  .app-header__navbar-logout {
    margin-left: auto;
  }

  .app-header__navbar-item {
    padding: 15px 10px;
  }
}

@media only screen and (min-width: 1200px) {
  .app-header__navbar-item {
    padding: 15px 15px;
  }
}
