.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown__handler {
  background: transparent;
  border: none;
  padding: 0;
  -webkit-appearance: none;
}

.dropdown__items {
  display: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  list-style-type: none;
  width: 100%;
  z-index: 1;
}

.dropdown__items--align-right {
  left: auto;
  right: 0;
  width: auto;
}

.dropdown__items--open {
  display: inline-block;
}

.dropdown__item {
  line-height: 1.6;
  user-select: none;
  white-space: nowrap;
}