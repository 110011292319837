.account-details {
  padding: 25px 15px;
  font-size: 0.8125rem;
}

.account-details__list {
  margin-top: 0;
}

.account-detatils__item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.account-details__data {
  color: #6f6f6f;
  opacity: 0.6;
}

.account-details__value {
  color: #3e3935;
  font-family: 'GothamMedium';
  font-weight: 400;
  opacity: 0.8;
  white-space: nowrap;
}

.hover-pointer {
  cursor: pointer;
}
