.msg-bar {
  display: block;
  left: 0;
  min-height: 56px;
  padding: 16px;
  position: fixed;
  z-index: 1002;
  text-align: center;
  top: 0;
  width: 100%;
}

.msg-bar__text {
  color: white;
}

.msg-bar--no-display {
  display: none;
}

.msg-bar--success {
  background-color: rgb(0, 167, 87);
}

.msg-bar--error {
  background-color: rgb(253, 87, 116);
}
