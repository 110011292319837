.app-footer {
  background-color: #3e3935;
  font-weight: 100;
}

.app-footer__content {
  max-width: 1170px;
  margin: 0 auto;
  padding: 15px 15px;
}

.app-footer__copyright {
  color: #FFFFFF;
  margin: 0;
}

.app-footer__legal-content {
  margin: 15px 0 0 0;
  padding: 0;
  list-style-type: none;
  cursor: default;
}

.app-footer__legal-content-item {
  display: inline-block;
}

.app-footer__legal-content-item:first-child {
  margin-right: 30px;
}

.app-footer__legal-content-item {
  margin-right: 30px;
}

.app-footer__legal-content-link {
  color: #FFFFFF;
  text-decoration: none;
}

@media only screen and (min-width: 480px) {
  .app-footer__content {
    display: flex;
    justify-content: space-between;
  }

  .app-footer__legal-content {
    margin-top: 0;
  }
}