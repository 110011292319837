.delivery-receipt-modal__title {
  text-align: center;
  font-size: 1.625rem;
  margin-bottom: 0;
  font-weight: 600;
}

.delivery-receipt-modal__message {
  margin-top: 0;
  margin-bottom: 15px;
  text-align: center;
}

.delivery-receipt-modal__body {
  padding: 0 35px 10px 35px;
}

.receipt-disclaimer {
  font-size: 8px;
  padding-top: 4px;
  margin-top: 4px;
}

.receipt-disclaimer-text {
  padding-top: 4px;
  font-size: 8px;
}

.receipt-info-icon {
  margin-right: 4px;
}