/**
 * Layout
 */
.flex-drow {
  flex-direction: row !important;
}

.flex-center {
  justify-content: center;
}

.nowrap-text {
  white-space: nowrap
}

.block {
  display: block;
}

.op-4 {
  opacity: .4;
}

.overflow-y-auto {
  overflow-y: auto;
}

.lmargin-auto {
  margin-left: auto;
}