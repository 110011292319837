.ReactModal__Body--open {
  overflow: hidden;
}

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100% - (1.75rem * 2));
  outline: none;
}

.modal {
  position: relative;
  background-color: #FFF;
  outline: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 6px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
  margin: 40px 25px;
  flex-grow: 1;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0,0,0,0.6);
}

.modal__content {
  max-height: 75vh;
  overflow: auto;
  padding: 0 65px 15px;
}

.modal__close {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  color: #BDBDBD;
}

.modal__close:hover {
  color: #8A8A8A;
  font-size: 16px;
  color: #686766;
  background: none;
  border: none;
}

.modal__header, .modal__body {
  padding: 15px;
}

.modal__body {
  padding-bottom: 25px;
}

.modal__title {
  color: #3e3935;
  font-size: 1.62rem;
  font-weight: 500;
}

.modal__header {
  padding-top: 35px;
}

/**
 * Modal for logo info.
 */

.modal-container--logo {
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
}

.modal--logo {
  margin-top: 10px;
}

.modal__overlay--logo {
  top: 56px;
}

@media only screen and (min-width: 768px) {
  .modal-container--logo {
    max-width: 768px;
  }
}

@media only screen and (min-width: 992px) {
  .modal-container--logo {
    max-width: 992px;
  }
}

@media only screen and (min-width: 1200px) {
  .modal-container--logo {
    max-width: 1200px;
  }
}