.react-datepicker-wrapper, .react-datepicker__input-container {
  width: 100%;
}

.datepicker {
  border: 1px solid #d9d6d4;
  border-radius: 4px;
  display: block;
  font-size: 16px;
  width: 100%;
  height: 42px;
  background-image: url('../../../assets/images/ic_calendar.png');
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
  background-size: 15px;
  padding: 14px;
  cursor: default;
}

.datepicker__input {
  border: none;
  font-size: 0.93rem;
  width: 100%;
}

.datepicker:after {
  content: "\F073";
  font-family: Font Awesome\ 5 Free;
  right: 12px;
  position: absolute;
  top: 12px;
  pointer-events: none;
}