.email-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.email-list__item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1px;
  padding: 10px 0;
  border-bottom: 1px solid #e6eaee;
}

.email-list__primary {
  line-height: 1;
  padding: .3em .6em .2em;
  background-color: #777;
  color: #fff;
  font-size: 0.6875rem;
  text-align: center;
}