.button {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #FFFFFF;
  line-height: 28px;
  font-family: 'GothamBold';
}

.button--short {
  height: 34px;
}

.button--small {
  font-size: 12px;
  line-height: normal;
  padding: 3px 8px;
}

.button--full {
  width: 100%;
}

.button[disabled], .btn[disabled]:hover {
  background-color: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
  filter: alpha(opacity=65);
  box-shadow: none;
  opacity: .65;
}

/**
 * Color modifiers
 */

.button--primary {
  border-color: rgb(121, 19, 37);
  background-color: rgb(121, 19, 37);
  opacity: .65;
}

.button--green {
  background-color: #00a757;
  border-color: #00a757;
}

.button--transparent {
  background-color: transparent;
  color: #3e3935;
}
