.page-options {
  background-color: #FFFFFF;
  box-shadow: 0 1px 0 0 #e3e3e3;
  border-top: 1px solid #f5f5f5;
}

.page-options__content {
  max-width: 1170px;
  margin: 0 auto;
  padding: 10px 15px;
}

.checkbox-inline__label {
  margin-right: 10px;
  margin-left: 10px;
}

@media only screen and (min-width: 768px) {
  .page-options__content {
    display: flex;
    flex-wrap: wrap;
    max-width: 750px;
    padding: 10px 0;
    align-items: center;
  }

  .filter-inline select {
    max-width: 230px;
  }
}

@media only screen and (min-width: 992px) {
  .page-options__content {
    max-width: 970px;
  }
}

@media only screen and (min-width: 1200px) {
  .page-options__content {
    max-width: 1170px;
  }
}