.options-dropdown {
  margin-top: 2px !important;
  padding: 5px 0 !important;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(62, 57, 53, 0.3);
}

.options-dropdown__handler {
  font-size: 16px;
  color: #949494;
}

.options-dropdown__item {
  padding: 2px 15px;
}

.options-dropdown__item:hover {
  background-color: #ececec;
}