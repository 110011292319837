@import './fonts.css';

* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
  color: #3e3935;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
}

p, label {
  font-family: 'GothamBook';
}

a {
  color: #3e3935;
  font-family: "GothamMedium";
}

h1, h2, h3, h4, h5, h6 {
  font-family: "GothamMedium";
  font-weight: 400;
}

body {
  font-family: 'GothamBook', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

.gothambook-font {
  font-family: 'GothamBook';
}

.gothammedium-font {
  font-family: "GothamMedium";
}

.gothambold-font {
  font-family: 'GothamBold';
}

button, input, textarea, select {
  font-family: 'GothamBook';
  outline: none;
}

.line-height-15 {
  line-height: 1.5;
}

.size-16 {
  font-size: 16px;
}

.default-cursor {
  cursor: default;
}

.color-gray {
  color: #949494;
}

.color-green {
  color: #00a757;
}

.color-red {
  color: #ee4a4f;
}

.main-color {
  color: #3e3935;
}

.relative {
  position: relative;
}

.weight-600 {
  font-weight: 600;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.grow-1 {
  flex-grow: 1;
}

.text-justify {
  text-align: justify;
}

.gmedium-common {
  font-family: "GothamMedium";
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-25 {
  margin-top: 25px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.modal-header-text {
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 30px;
}

.op-08 {
  opacity: .8;
}

.op-06 {
  opacity: .6;
}

.op-04 {
  opacity: .4;
}

.modal-paragraph {
  margin-top: 0;
  font-family: "GothamBook";
  font-size: 1.0625rem;
  line-height: 1.5;
}

.size-50per {
  width: 50%;
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.upper-text {
  text-transform: uppercase;
}

.common-title {
  font-family: 'GothamMedium';
  font-size: 1.625rem !important;
}

.w-100 {
    width: 100%;
}

.d-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

/**
 * Icons
 */
 .icon--red {
  color: #ee4a4f;
 }

 .icon--20px {
   width: 20px;
 }

.field-icon {
float: right;
margin-right: 8px;
margin-top: -26px;
position: relative;
z-index: 2;
cursor:pointer;
}

.circle-timer{
font-size: 38px;
font-weight: bold;
padding-top: 15px;
padding-bottom: 15px;
}

.stamps-red {
	color: #ee4a4f;
	font-size: 23px;
	font-weight: 700;
	border: 0.25rem solid #ee4a4f;
	display: inline-block;
	padding: 2px 7px;
	text-transform: uppercase;
	border-radius: 1rem;
	font-family: 'Courier';
  -webkit-mask-image: url('../../assets/images/stamp-mask.png');
  mask-image: url('../../assets/images/stamp-mask.png');
  -webkit-mask-size: 944px 604px;
  mask-size: 944px 604px;
  mix-blend-mode: multiply;
  -webkit-mask-position: 13rem 6rem;
  mask-position: 13rem 6rem;
	transform: rotate(-7deg);
  border-radius: 5px;
  color: #ee4a4f;
}

.routing-info-img{
  margin: 0 auto;
  width: 80%;
}
.routing-info-in span{
  font-size: 14px;
  font-weight: 600;
  word-wrap: break-word;
}

.routing-info-one{
  float: left;
  width: 160px;
}

.routing-info-two{
  float: left;
  width: 150px;
}

.routing-info-three{
  float: right;
  width: 150px;
}

.padding-20px{
  padding: 20px;
}