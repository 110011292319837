@media only screen and (min-width: 768px) {
  .app-home {
    display: flex;
    flex-wrap: wrap;
  }

  .app-home__load-account {
    flex-shrink: 1;
    flex-basis: 41%;
    margin-right: 1%;
  }

  .app-home__chat {
    flex-shrink: 1;
    flex-basis: 57%;
    margin-left: 1%;
  }

  .app-home__account-details {
    flex-basis: 41%;
  }
}

@media only screen and (min-width: 992px) {
  .app-home__load-account {
    flex-basis: 32%;
    margin-right: 1%;
  }

  .app-home__chat {
    flex-basis: 66%;
    margin-left: 1%;
  }

  .app-home__account-details {
    flex-basis: 32%;
  }
}

@media only screen and (min-width: 1200px) {
  .app-home__load-account {
    flex-basis: 32%;
    margin-right: 1%;
  }

  .app-home__chat {
    flex-basis: 32%;
    margin-left: 1%;
    margin-right: 1%;
  }

  .app-home__account-details {
    flex-basis: 32%;
    margin-left: 1%;
  }
}