.form__title {
  color: #3E3932;
  font-size: 1.87rem;
  font-weight: 500;
  margin-top: 0;
}
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.form-group--row-layout {
  flex-direction: row;
  justify-content: space-between;
}

.form-group--horizontal {
  flex-direction: row;
  align-items: center;
}

.form-group__label {
  color: #3e3935;
  margin-bottom: 5px;
  font-family: "GothamMedium";
  font-size: 13px;
  opacity: 0.6;
  text-align: left;
}

.form-group__label--horizontal {
  margin-bottom: 0;
  margin-right: 10px;
  white-space: nowrap;
}

.form-group__input {
  border: 1px solid #d9d6d4;
  border-radius: 4px;
  display: block;
  font-size: 0.93rem;
  height: 42px;
  padding: 6px 12px;
  width: 100%;
  background-color: white;
}

.form-group__help {
  display: block;
  margin-top: .25rem;
}

.form-group__error {
  color: #a94442;
  font-size: 0.87rem;
  display: block;
  padding-top: 5px;
  text-align: left;
}
.form-group__submit-button {
  background-color: rgb(133, 175, 64);
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 0.87rem;
  font-weight: 700;
  height: 44px;
  padding: 6px 12px;
  text-align: center;
}
.form-group__submit-button:disabled {
  cursor: not-allowed;
  opacity: 0.65;
}
.form-group__submit-button--vertical-margin {
  margin: 6px 0;
}
.form-link {
  color: #00a757;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}
.logo-img {
  width: 100%;
}
.logo-img__container {
  margin-top: 100px;
  margin-bottom: 30px;
  text-align: center;
}
.sign-up-link__container {
  text-align: center;
}
.sign-up-link {
  color: #00a757;
  cursor: pointer;
  text-decoration: none;
}
.otp-form__digits-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.otp-digit {
  border: 1px solid #d9d6d4;
  border-radius: 4px;
  height: 42px;
  display: inline-block;
  font-size: 15px;
  padding: 6px 12px;
  text-align: center;
  width: 60px;
}

.btn-facebook {
  box-sizing: content-box;
  height: 38px;
  padding: unset;
  border-radius: 3px;
  border: 1px solid #4c69ba;
  font-size: 16px;
  font-family: Helvetica,sans-serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  transition: background-color .3s,border-color .3s;
  background-color: #4c69ba;
}

.btn-facebook i.fa-facebook {
  margin-right: 8px;
}


.file-input--hidden {
  display: none;
}

.file-input__upload {
  cursor: pointer;
  min-height: 12.5rem;
  border: 1px dashed #7C8DA0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.file-input__upload-text {
  line-height: 0.875rem;
  letter-spacing: 0.3px;
  font-weight: 600;
  font-size: 0.75rem;
  color: #8e9197;
}
.file-input__icon {
  color: #7C8DA0;
  font-size: 2.25rem;
}

.image-preview {
  display: flex;
  position: relative;
}

.image-preview__actions {
  position: absolute;
  bottom: 0.75rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-preview__action {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.75rem;
  height: 1.75rem;
  color: #FFF;
  border-radius: 50%;
}

.image-preview__action--positive {
  background-color: #00a757;
}

.image-preview__action--negative {
  background-color: #e7433d;
}