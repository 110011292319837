/* Header *
************/

.app-header {
  background-color: #FFFFFF;
  border-radius: 1px;
  min-height: 50px;
  margin-bottom: 0;
  box-shadow: inset 0px -6px 0 0px #ccc;
}

.app-header__content {
  max-width: 1170px;
  margin: 0 auto;
  padding: 10px 15px;
  margin-right: auto;
  margin-left: auto;
}

/* Content *
************/

.content-wrapper {
  min-height: calc(100vh - 100px);
}

.content-wrapper-custom-footer {
  min-height: calc(100vh - 165px);
}

.content {
  max-width: 1170px;
  margin: 0 auto;
  padding: 20px 15px 30px 15px;
}

/* Modals *
************/

.modal--width-500px {
  max-width: 500px;
}

.modal--width-750px {
  max-width: 750px;
}

/************************
* Media queries
*************************/

@media only screen and (min-width: 768px) {
  .app-header__content, .content {
    max-width: 750px;
  }

  .content {
    padding: 10px 0 30px 0;
  }
}

@media only screen and (min-width: 992px) {
  .app-header__content {
    padding: 0;
    display: flex;
  }
  .app-header__content, .content {
    max-width: 970px;
  }
}

@media only screen and (min-width: 1200px) {
  .app-header__content, .content {
    max-width: 1170px;
  }
}