.app-receipts__item {
  margin-bottom: 10px;
}

.app-receipts__paginator {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

@media only screen and (min-width: 768px) {
  .app-receipts__list {
    display: flex;
    flex-wrap: wrap;
  }

  .app-receipts__item {
    flex-basis: calc(50% - 10px);
    max-width: calc(50% - 10px);
    margin-right: 10px;
  }
}

@media only screen and (min-width: 992px) {
  .app-receipts__item {
    flex-basis: calc(33.3333% - 10px);
    max-width: calc(33.3333% - 10px);
  }
}

@media only screen and (min-width: 1200px) {
  .app-receipts__item {
    flex-basis: calc(25% - 10px);
    max-width: calc(25% - 10px);
  }
}

div.receipt__payment-status {
  transform: rotate(-15deg);
  margin-top: 40px;
}

span.receipt__payment-status {
  font-size: 10px;
  font-weight: 850;
  border-width: 2px;
  border-style: solid;
  border-radius: 4px;
  padding: 5px 8px 4px 10px;
}

div.sch_receipt__payment-status {
  transform: rotate(-15deg);
  margin-top: 20px;
}

span.sch_receipt__payment-status {
  font-size: 8px;
  font-weight: 600;
  border-width: 2px;
  border-style: solid;
  border-radius: 4px;
  padding: 4px 6px 2px 8px;
}