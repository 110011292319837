@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700');
@import '../../assets/css/fontawesome-all.min.css';

@font-face {
  font-family: "GothamMedium";
  src:  url(../../assets/webfonts/GothamMedium/GothamMedium.woff2) format('woff2'), 
        url(../../assets/webfonts/GothamMedium/GothamMedium.woff) format('woff'), 
        url(../../assets/webfonts/GothamMedium/GothamMedium.ttf) format("opentype");
}

@font-face {
  font-family: "GothamBook";
  src:  url(../../assets/webfonts/GothamBook/GothamBook.woff2) format('woff2'), 
        url(../../assets/webfonts/GothamBook/GothamBook.woff) format('woff'), 
        url(../../assets/webfonts/GothamBook/GothamBook.ttf) format("opentype");
}

@font-face {
  font-family: "GothamBold";
  src:  url(../../assets/webfonts/GothamBold/GothamBold.woff2) format('woff2'),
        url(../../assets/webfonts/GothamBold/GothamBold.woff) format('woff'),
        url(../../assets/webfonts/GothamBold/GothamBold.ttf) format("opentype");
}

@font-face {
  font-family: "payixIcons";
  src: url(../../assets/webfonts/payix-icons.woff) format('woff')
}
