.money-input {
  display: table;
}

.money-input__addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
}

.money-input__textfield {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 42px;
  border: 1px solid #d9d6d4;
  box-shadow: none;
  font-size: 15px;
  color: #3e3935;
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 0;
  display: block;
  padding: 6px 12px;
  line-height: 1.42857143;
  background-color: #fff;
  background-image: none;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  text-align: right;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: none;
}

.money-input-simple {
  height: 42px;
  border: 1px solid #d9d6d4;
  box-shadow: none;
  font-size: 15px;
  color: #3e3935;
  width: 100%;
  margin-bottom: 0;
  display: block;
  padding: 6px 12px;
  line-height: 1.42857143;
  background-color: #fff;
  background-image: none;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  text-align: right;
}