.congratulations-modal__body {
  padding: 0 35px 10px 35px;
}

.congratulations-modal__success-wrapper {
  text-align: center;
  font-size: 1.0625rem;
  line-height: 1.3;
}

.congratulations-modal__payment-scheduled {
  color: #929292;
  margin: 5px;
  font-weight: 300;
}

.congratulations-modal__details {
  display: flex;
  padding: 25px 0;
  margin-bottom: 20px;
  background: #fafafa;
}

.congratulations-modal__payment-details {
  width: 65%;
  border-right: 1px dashed #e2dfde;
  padding-left: 15px;
  color: #656464;
  font-family: "GothamMedium";
}

.congratulations-modal__value {
  font-size: 1.375rem;
  margin-bottom: 5px;
  font-weight: 500;
}

.congratulations-modal__payment-frequency {
  font-size: 11px;
  font-weight: 400;
  opacity: 0.6;
  text-transform: uppercase;
}

.congratulations-modal__card-franchise {
  margin: 10px 0 5px 0;
  font-family: "GothamMedium";
}

.congratulations-modal__card-number {
  font-family: "GothamMedium";
  font-size: 13px;
  opacity: 0.6;
}

.congratulations-modal__date-wrapper {
  width: 35%;
  color: #57514d;
  font-size: 0.75rem;
}

.congratulations-modal__date {
  text-align: center;
  line-height: 1.5;
}

.congratulations-modal__month {
  font-size: 1.375rem;
}

.panel__height-auto {
    height: auto;
}

.receipt__label-non-uppercase {
    text-transform: none;
}

/**
 * Options
 */

.status-filter select {
  min-width: 130px;
}

@media only screen and (min-width: 768px) {
  .status-filter {
    margin-right: 15px;
  }
}
