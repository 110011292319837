.loan-account__top-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.loan-account__accounts-dropdown-wrapper {
  position: relative;
}

.loan-account__accounts-dropdown {
  padding: 10px;
  border: 1px solid transparent;
  font-size: 14px;
  background: #fff;
  border-radius: 3px;
  color: #57514d;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.18);
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
}

.loan-account__accounts-dropdown:active {
  box-shadow: none;
}

.loan-account__accounts-dropdown-caret {
  margin-left: 10px;
}

.loan-account__accounts-dropdown-item {
  display: block;
  padding: 10px 20px;
  white-space: nowrap;
  user-select: none;
}

.loan-account__accounts-dropdown-item--green {
  color: #00a757;
}

.loan-account__accounts-dropdown-item:hover {
  background-color: #f5f5f5;
}

.loan-account__accounts-dropdown-item--active, .loan-account__accounts-dropdown-item--active:hover {
  background-color: #dcdcde;
}

.loan-account__header {
  padding: 7px 15px 3px 25px;
  display: flex;
}

.loan-account__collateral {
  margin: 0;
  font-size: 0.75rem;
  font-weight: normal;
}

.loan_account__model {
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.3;
}

.loan_account__no-collateral {
  margin: 10px 0;
  font-size: 0.875rem;
  line-height: 1.3;
}

.load-account__options-wrapper {
  position: relative;
  margin-left: auto;
  padding-right: 0;
  align-self: center;
}

/* Picture *
****************/

.load-account__picture {
  position: relative;
  margin: 0;
}

.load-account__upload-picture-btn {
  background: transparent;
  border: none;
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 40px;
  height: 30px;
  background-image: url(../../assets/images/camera.png);
  background-size: cover;
}

.loan-account__vehicle {
  max-height: 250px
}

.loan-account__vehicle-img {
  width: 100%;
}

.loan-account__user-image {
  display: inline-block;
  position: absolute;
  bottom: -35px;
  left: 10px;
  border: 2px solid #3e3935;
  width: 70px;
  height: 70px;
  background: url(../../assets/images/dummy.jpg);
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  border-radius: 100%;
}

.loan-account__user-image-edit {
  position: absolute;
  bottom: -3px;
  right: 0px;
  z-index: 1;
}

.loan-account__vehicle-image-edit {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 50px !important;
  height: 50px !important;
  font-size: 22px;
}

/* Payment data *
****************/

.load-account__payment-data-wrapper {
  padding: 0 15px;
  position: relative;
}

.loan-account__impress {
  height: 35px;
  position: absolute;
  top: 5px;
  right: 15px;
}

.loan-account__impress-left {
  height: 35px;
  position: relative;
  top: 5px;
  right: 15px;
}

.load-account__profile {
  display: flex;
  height: 50px;
}

.loan-account__borrower {
  font-size: 0.875rem;
  text-transform: uppercase;
  margin-left: 70px;
  margin-top: 10px;
}

/* Last payment & Next payment *
********************************/

.money {
  display: block;
  font-family: "GothamMedium";
  font-size: 18px;
}

.money--block {
  display: block;
}

.money--green {
  color: #00a757;
}

.money--red {
  color: #fd5774;
}

.load-account__left-payment {
  text-align: left;
}

.load-account__next-payment {
  text-align: right;
}

.load-account__money-legend, .load-account__money-date {
  display: block;
  opacity: .4;
  font-family: "GothamMedium";
}

.load-account__money-amount {
  font-size: 12px;
  font-weight: 600;
  opacity: 0.6;
  margin-bottom: 5px;
  margin-top: 10px;
}

.load-account__total {
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  margin-bottom: 10px;
}

.load-account__disclaimer {
  font-size: 12px;
  font-weight: 600;
  opacity: .6;
  margin-top: 15px;
}

/* Payment method dropdown *
****************/

.load-account__payment-form {
  padding-top:  20px;
}

.loan-account_schedule-fields {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  min-height: 100%;
}

.loan-account_payment-method {
  position: relative;
  height: 42px;
  width: 100%;
  border: 1px solid #000;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.18);
  text-align: left;
  padding: 0 20px;
  border-color: #00a757;
  color: #00a757;
  font-family: 'GothamBook';
}

.loan-account__payment-method-display-btn {
  position: absolute;
  width: 10px;
  height: 10px;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.loan-account__payment-method-items {
  width: 100%;
}

.loan-account_payment-method-item {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  user-select: none;
}

.loan-account_payment-method-item:hover {
  color: #262626;
  background-color: #f5f5f5;
}

.loan-account_payment-method-item--green {
  color: #00a757;
  font-weight: 600;
  font-size: 17px;
}

.loan-account_payment-method-item--green:hover {
  color: #00a757;
}

.loan-account_payment-method-desc, .loan-account__selected-desc {
  margin: 0;
  margin-left: 20px;
  font-size: 0.875rem;
  line-height: 20px;
}

.loan-account__selected-desc {
  font-size: 11px;
  line-height: 15px;
}

.payment-method__text {
  margin: 0;
  margin-left: 20px;
  font-size: 0.6875rem !important;
}

.loan-account_payment-method-selected {
  display: flex;
  align-items: center;
  user-select: none;
}

/* Schedule Date & Payment Frequency *
**************************************/

.loan-account__schedule-date {
  display: flex;
  flex-basis: calc(50% - 5px);
  justify-content: center;
  flex-direction: column;
  margin-right: 5px;
}

.loan-account__payment-frequency {
  display: flex;
  flex-basis: calc(50% - 5px);
  justify-content: center;
  flex-direction: column;
  margin-left: 5px;
}

.loan-account__schedule-date-input {
  border: none;
  font-size: 0.93rem;
  width: 100%;
}

.loan-account__schedule-datesss input {
  border: 1px solid #d9d6d4;
  border-radius: 4px;
  display: block;
  font-size: 0.93rem;
  height: 42px;
  padding: 6px 12px;
  width: 100%;
  position: absolute;
  bottom: 0;
}

div.loan__payment-status {
  transform: rotate(-15deg);
  margin: 20px 0px 0px 50px;
}

div.loan__payment-status-left {
  transform: rotate(-15deg);
  margin: 10px 20px 20px 10px;
}

span.loan__payment-status {
  font-size: 10px;
  font-weight: 800;
  border-width: 2px;
  border-style: solid;
  border-radius: 4px;
  padding: 4px 6px 2px 8px;
  color: #00a757;
  border-color: #00a757;
  text-transform: uppercase;
}

.margin-tile {
  margin: 11px 25px;
}