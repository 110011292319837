.receipt {
  display: flex;
  min-height: 200px;
  font-size: 0.75rem;
  padding: 20px 10px 20px 15px;
  background-color: #fff;
  box-sizing: border-box;
  width: 100%;
}

.receipt__options-wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
}

.receipt__left-content {
  width: 60%;
  border-right: solid 1px #eceae9;
}

.receipt__right-content {
  width: 40%;
  position: relative;
}

.receipt__label, .receipt__value, .receipt__label--payment, .receipt__value--payment {
  font-size: 10px;
  font-family: "GothamMedium";
  font-weight: 400;
}

.receipt__label, .receipt__label--payment {
  opacity: .4;
  line-height: 1.5;
}

.receipt__value, .receipt__value--payment {
  overflow: hidden;
  margin: 0;
  line-height: 1.5;
  opacity: .8;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.receipt__value--payment {
  color: #57514d;
  font-size: 14px;
  opacity: 1;
}

.receipt__label--payment {
  opacity: .6;
  font-size: 13px;
}

.receipt__impress {
  width: 65px;
}

.receipt__date, .receipt__date--payment {
  text-align: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'GothamMedium';
  font-size: 14px;
}

.receipt__date--payment {
  color: #57514d;
  font-size: 22px;
}
.opacity-1{
  opacity: 1 !important;
}

.receipt__date--payment span {
  font-size: 13px;
}

.receipt__data-section {
  margin-bottom: 8px;
}

.panel {
  width: 150px;
  height: 180px;
  font-size: 14px;
  font-weight: 600;
  padding: 8px;
  background: #fbfbfb;
  color:black;
  border-radius: 4px;
  -webkit-box-shadow: 3px 6px 12px 0px #d8d8d8;
  box-shadow: 3px 6px 12px 0px #d8d8d8;
}

.payment-detail{
  position: absolute;
  z-index: 1;
}

@keyframes in {
  from {height: 0px;}
  to {height: 180px;}
}

@keyframes out {
  from {height: 180px;}
  to {height: 0px;}
}


.payment-enter {
  height: 0px;
  color: #ffffff00;
}

.payment-enter.payment-enter-active {
  height: 180px;
  color:black;
  animation-duration: 1s;
  animation-name: in;
  transition-property: all;
  transition-duration: 900ms;
  transition-delay: 650ms;
}

.payment-leave.payment-leave-active {
  height: 0px;
  color: #ffffff00;
  animation-duration: 1s;
  animation-name: out;
}

.receipt__data-section--error {
  color: #fd5774;
  font-family: 'GothamMedium';
  font-size: 9px;
  text-transform: uppercase;
}

.receipt__bold-text {
  font-weight: 600;
  margin: 3px 0;
}

.receipt__platform {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.75rem;
}

.info__sch_msg{
  text-align: center;
  margin-top: 10px;
  margin-right: 14px;
}

.button--red {
  background-color: #e30f0e;
  border-color: #e30f0e;
}

.rc-tooltip-inner {
  width: 250px;
}