.settings-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.settings-menu__item {
  margin-bottom: 10px;
  line-height: 20px;
  border-bottom: 1px solid #e2e2e2;
}

.settings-menu__item-link {
  font-family: 'GothamBook';
  text-decoration: none;
}

.settings-menu__item-icon {
  width: 20px;
  margin-right: 10px;
}

.settings-card {
  padding: 15px 10px;
  background-color: #fff;
  border-radius: 4px;
}

.settings-card__title {
  margin: 5px 0 10px 0;
}

/**
 * Contact information
 */
.contact-info__loan-accounts {
  margin-top: 5px;
}

.contact-info__loan-statements {
  margin-top: 5px;
  margin-bottom: 20px;
}

.contact-info__loan-accounts-label {
  margin-right: 10px;
}

.contact-info__edit-button {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (min-width: 768px) {
  .app-settings {
    display: flex;
  }
  
  .app-settings__menu {
    flex-shrink: 0;
    width: 33%;
  }
  
  .app-settings__content {
    flex-grow: 1;
  }

  .settings-menu__item {
    line-height: 56px;
    width: 80%;
  }
}

/**
 * Payment methods
 */
.payment-method-card {
  display: inline-block;
  width: 100%;
  border: 2px solid #e9e9e9;
  border-radius: 8px;
}

.payment-method {
  padding: 20px 30px;
  height: 180px;
  font-size: 1rem;
  font-family: 'GothamBook';
}

.payment-method__img {
  margin-right: 15px;
  height: 24px;
}

.payment-method__number {
  font-family: 'GothamMedium';
}

.payment-method__last-four-number {
  font-size: 24px;
}

.payment-method__data {
  display: block;
  font-size: 12px;
  opacity: 0.4;
}

.payment-methods__item {
  margin-bottom: 30px;
}

.add-payment-method {
  padding: 20px 30px;
  color: #00a757;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
  height: 180px;
}

.add-payment-method--icon {
  font-size: 30px;
}

@media only screen and (min-width: 480px) {
  .settings-card {
    padding: 15px 30px;
  }
}

@media only screen and (min-width: 992px) {
  .payment-methods__item {
    display: inline-block;
    padding: 0 10px;
    vertical-align: top;
    width: 50%;
  }
}