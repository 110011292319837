.app-schedule__receipts {
  margin-top: 20px;
}

.app-schedule__receipt {
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
  .app-schedule {
    display: flex;
  }

  .app-schedule__load-account {
    flex-shrink: 1;
    flex-basis: 49%;
    margin-right: 1%;
  }

  .app-schedule__receipts {
    margin-top: 0;
    margin-left: 1%;
    flex-shrink: 1;
    flex-basis: 49%;
  }
}

@media only screen and (min-width: 992px) {
  .app-schedule__load-account {
    flex-basis: 32%;
  }

  .app-schedule__receipts {
    flex-basis: 66%;
  }

  .app-schedule__receipt {
    width: 32%;
    display: inline-block;
  }

  .app-schedule__receipt:nth-child(3n+1) {
    margin-right: 1%;
  }

  .app-schedule__receipt:nth-child(3n+2) {
    margin-left: 1%;
    margin-right: 1%;
  }

  .app-schedule__receipt:nth-child(3n+3) {
    margin-left: 1%;
  }
}
