.admin-panel {
  background-color: #f5f5f5;
  color: #3e3935;
}

.section-title {
  font-size: 1.125rem;
  margin: 10px 0;
  display: inline-block;
  margin-right: 10px;
}

.btn-transparent {
  background: transparent;
  border: 0;
}

input[disabled] {
  background-color: #e8e8e8;
}

/**
 * Modal
 */

.modal__note-wrapper {
  position: relative;
  margin-bottom: 0;
  padding: 40px 50px;
  background-color: #e5e2e0;
  text-align: center;
}

.modal__note-wrapper:before, .modal__note-wrapper:after  {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  right: 0;
  content: "";
}

.modal__note-wrapper:before {
  border-top: solid 20px #3e3935;
  border-right: solid 20px #3e3935;
  border-left: solid 20px transparent;
  border-bottom: solid 20px transparent;
}

.modal__note-wrapper:after {
  border-bottom: solid 20px #d0cdcb;
  border-left: solid 20px #d0cdcb;
  border-right: solid 20px transparent;
  border-top: solid 20px transparent;
  border-radius: 0 0 0 10px;
}

.options-menu-item {
  display: block;
  user-select: none;
  padding: 8px 15px;
  font-weight: 500;
}

/**
 * Loan account.
 */

.loan-account-placeholder {
  margin: 0 auto;
  margin-top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  background-color: #FFF;
}

.loan-account-placeholder__icon-add {
  font-size: 50px;
}

.loan-account-placeholder__text {
  font-weight: 500;
}

/**
 * Message for empty sections.
 */
.empty-message {
  flex-grow: 1;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty-message__icon {
  font-size: 50px;
}

.empty-message__text {
  font-weight: 500;
  font-size: 20px;
}

/**
 * Ach Terms modal
 */
.ach-terms {
  margin-bottom: 25px;
  text-align: justify;
  font-size: 0.75rem;
  line-height: 1.5;
}

/**
 * Payment methods
 */
.payment-methods__title {
  font-size: 18px;
}

.payment-methods__description {
  line-height: 20px;
  color: #57514d;
}