.home-container {
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
}

.home-container-custom-footer {
  display: flex;
  flex-wrap: wrap;
  height: 90vh;
}

.home-container-custom-footer-signup {
  display: flex;
  flex-wrap: wrap;
  height: 110vh;
}

.home-image {
  display: none;
}

.home-login-form {
  padding: 0 40px 30px 40px;
  width: 100%;
}

.sign-in-form__container {
  margin: 0 auto;
  max-width: 400px;
}

.sign-form__social-buttons {
  display: flex;
  margin-bottom: 30px;
}

.sign-form__social-buttons span:first-child {
  display: flex;
  flex-basis: calc(50% - 5px);
  justify-content: center;
  flex-direction: column;
  margin-right: 5px;
}

.terms-conditions {
  height: 75vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.terms-conditions__content {
  color: #57514d;
  line-height: 20px;
}

.terms-conditions__content .pg-heading {
  font-size: 22px;
}

@media screen and (min-width: 768px) {
  .home-image {
    background-color: gray;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex: 3;
  }

  .home-login-form {
    flex: 2;
    overflow-y: auto;
  }
  
  .half-responsive {
    display: inline-block;
    width: 46%;
  }

  .pull-right {
    float: right;
  }

  .half-responsive:last-child {
    margin-left: 8%;
  }
}

.password-notification {
  padding: 5px;
  width: 100%;
  margin: 0 auto;
  color: brown;
}

.password-noti-in{
  padding: 5px;
  width: 100%;
}