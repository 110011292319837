.whole-screen-loader {
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  width: 100%;
  z-index: 1001;
}

.whole-screen-loader .whole-screen-loader__spinner {
  animation: spinner-spin infinite 5s linear;
  color: #FAFAFA;
  font-size: 4rem;
}

@keyframes spinner-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
