.dropdown-menu {
  position: absolute;
  top: 100%;
  margin: 0;
  margin-top: 2px;
  padding: 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 4px;
  list-style-type: none;
  z-index: 1;
  display: none;
}

.dropdown-menu--right {
  right: 0;
}

.dropdown-menu--open {
  display: block;
}